.about-section {
   margin-top: 50px;
   margin-bottom: 50px;
   height: min-content;
   background-color: #7a25f3;
   padding: 20px 20px 20px 20px;

   .flex-description {
      margin-top: 50px;
      margin-left: 5%;
      margin-right: 5%;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;
   }

   .about-text {
      font-size: 1.5rem;
      font-weight: 600;
      min-width: 50%;
      padding-left: 5%;
      padding-right: 5%;
      margin-bottom: 50px;
      color: white;
      flex: 2;

      @media screen and (max-width: 500px) {
         font-size: 0.85rem;
      }
   }

   .flex-button {
      margin-left: 20%px;
      margin-right: 20%px;
      color: lightgray;
      display: flex;
      flex-direction: column;
      align-items: center;
   }

   .credit-text {
      margin-top: 10px;
      margin-bottom: 10px;
   }

   .button-link {
      text-decoration: none;
      cursor: pointer;
      margin-left: 5%;
      margin-right: 5%;
   }
}

.purple-text {
   color: #7805f7;
   font-weight: 600;
}

.green-text {
   color: #61ce70;
   font-weight: 600;
   // -webkit-text-stroke: 1px white;
}

.pink-text {
   color: #ff8811;
   font-weight: 600;
   // -webkit-text-stroke: 1px white;
}

.material-button {
   background-color: white !important;

   &:hover {
      background-color: lightgray !important;
      transform: scale(1.05);
      transition: transform .2s;
   }
}
