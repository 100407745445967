.skill-title {
   margin: 20px auto 20px auto;
   color: rgb(112, 112, 112);
}

.skill-container {
   width: 130px;
   height: fit-content;
}

@media screen and (min-width: 800px) {
   .skill-title {
      font-size: 24px;
   }
}

@media screen and (max-width: 800px) {
   .skill-title {
      font-size: 14px;
   }
}
