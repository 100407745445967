.social-links-section {
   height: auto;
   width: 100%;
   max-width: 200px;
   margin: 20px auto 50px auto;

   .social-text {
      margin-bottom: 20px;
      .text {
         font-size: 1.2rem;
      }
      @media screen and (max-width: 800px) {
         .text {
            font-size: 1.1rem;
         }
      }
   }

   .social-icons {
      display: flex;
      justify-content: space-around;
      .icon {
         &:hover {
            transform: scale(1.2);
            transition: transform .2s;
         }
      }
   }
}
