* {
   margin: 0px;
   padding: 0px;
}

body {
   margin: 0;
   font-family: 'Lato', sans-serif;
   color: #444;
   text-align: center;
}

.App-dark {
   background-color: #252627;
   transition: .2s;
}

.App-light {
   margin-top: 0;
   background-color: #fff;
   transition: .2s;
}

.bold-purple {
   color: #7805f7;
}

.heading-text {
   font-weight: 800;
}

.bold-purple-underline {
   color: #7805f7;
   text-decoration: underline;
}

.normal-text {
   font-size: 3vw;
}

@media screen and (min-width: 1200px) {
   .normal-text {
      font-size: 30px;
   }
}
