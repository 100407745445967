.projects-section {
    margin: 100px 5% auto 5%;
    padding-bottom: 50px;
    padding-left: 3%;
    padding-right: 3%;
    transition: 0.2s;
}

.projects-light {
    border: none;
}

.projects-dark {
    border: none;
}

.title-dark {
    color: rgb(112, 112, 112);
}

.projects-section-title {
    padding-top: 50px;
    margin-bottom: 50px;
    font-size: 38;
}

@media screen and (max-width: 800px) {
    .projects-section-title {
        font-size: 4vw;
    }
}

//		box-shadow: 2px 2px 4px #fb275d;
