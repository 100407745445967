.main-component {
   margin: 20px 10% 20px 10%;

   &:hover {
      & .project-image {
         transform: scale(1.3);
         transition: transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
      }
      & .image-link {
         opacity: 0.9;
         transition: .2s;
      }
   }
}

.main-component.dark {
   background-color: #2b2b2b !important;
   color: rgb(112, 112, 112) !important;
   transition: .2s !important;
}

.main-component.light {
   background-color: #f8f8f8 !important;
   transition: .2s !important;
}

.image-container {
   flex: 1 1 auto;
   display: flex;
   align-items: center;
   justify-content: center;
   margin: 20px 0 20px;
   overflow: hidden;
   // border-top: 2px solid #fb275d;
   // border-bottom: 2px solid #fb275d;
   position: relative;
}

.technology {
   text-align: left !important;
   line-height: 2em !important;
   font-size: 20px !important;

   @media screen and (max-width: 800px) {
      font-size: 14px !important;
   }
}

.primary-name {
   margin-top: 20px !important;
   font-weight: 600 !important;
   color: #7805f7 !important;
   @media screen and (max-width: 800px) {
      font-size: 16px !important;
   }
}

.project-image {
   width: 350px;
   max-width: 100%;
   background-size: cover;
   background-position: center;
}

.image-link {
   color: #fb275d;
   text-decoration: none;
   font-weight: 600;
   height: 70px;
   width: 360px;
   max-width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   border-top: 2px solid #fb275d;
   border-bottom: 2px solid #fb275d;
   // border: 1px solid #fb275d;
   background-color: white;
   position: absolute;
   opacity: 0;
   &:hover {
      cursor: pointer;
      background-color: #fb275d;
      color: white;
      transition: .2s;
   }
}

.github-link {
   position: absolute !important;
   bottom: 0;
   left: 0 !important;
   right: 0 !important;
   margin-left: auto !important;
   margin-right: auto !important;
}
