.skills-section {
   background-color: #f8f8f8;
   margin: auto 50px auto 50px;
   padding-bottom: 50px;
   border-radius: 10%;
   transition: .2s;
}

.skills-section.light {
   background-color: #f8f8f8;
}

.skills-section.dark {
   background-color: #2b2b2b;
   color: rgb(112, 112, 112);
}

.skills-section-title {
   padding-top: 50px;
   margin-bottom: 50px;
   font-size: 38;
}

@media screen and (max-width: 800px) {
   .skills-section-title {
      font-size: 4vw;
   }
}
