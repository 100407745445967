.intro-section {
   margin: 0px auto 0px auto;
   padding-top: 100px;
   padding-bottom: 20px;
   font-weight: 900;
   font-size: 5vw;
   img {
      height: 250px;
      border-radius: 10%;
      margin-bottom: 50px;
   }

   .dark-text {
      color: rgb(112, 112, 112);
   }

   .five-stars {
      margin: 50px auto;
      img {
         margin-top: auto;
         margin-bottom: auto;
         width: 20vw;
         height: auto;
      }
   }

   @media screen and (min-width: 1200px) {
      span {
         font-size: 50px;
      }
      .hello-text {
         font-size: 50px;
      }
   }
}
