.footer {
   background-color: whitesmoke;
   color: #444;
   font-weight: 200;
   width: 100%;
   padding-top: 10px;
   padding-bottom: 10px;
}

.dark-footer {
   background-color: #2b2b2b;
   color: rgb(112, 112, 112);
}

@media screen and (min-width: 1200px) {
   .footer {
      font-size: 22px;
   }
}

@media screen and (max-width: 800px) {
   .footer {
      font-size: 14px;
   }
}
